import React from 'react';

import './styles/index.scss';

export type TIconType =
  | 'account'
  | 'add'
  | 'article'
  | 'back'
  | 'ban'
  | 'cancel'
  | 'comments'
  | 'delete'
  | 'double-left-arrow'
  | 'double-right-arrow'
  | 'down-arrow'
  | 'launch'
  | 'menu-vertical'
  | 'photo'
  | 'refresh'
  | 'reset'
  | 'save'
  | 'subtract'
  | 'tick'
  | 'unknown'
  | 'up-arrow'
  | 'up-votes'
  | 'video'
  | 'warning';

const showIcon = (icon: TIconType): JSX.Element => {
  switch (icon) {
    case 'add':
      return (
        <path d="M48.7,34.4H34.4v14.3h-4.7V34.4H15.3v-4.7h14.3V15.3h4.7v14.3h14.3V34.4z" />
      );
    case 'subtract':
      return <path d="M15.3,34.4v-4.8h33.3v4.8H15.3z" />;
    case 'account':
      return (
        <path
          d="M10.1,10.1C16.2,4,23.5,1,32,1s15.8,3,21.9,9.1S63,23.5,63,32s-3,15.8-9.1,21.9S40.5,63,32,63
    s-15.8-3-21.9-9.1S1,40.5,1,32S4,16.2,10.1,10.1z M32,54.4c7.9,0,14.1-3.3,18.6-10c-0.1-2.7-2.3-5-6.5-6.8s-8.3-2.8-12.1-2.8
    s-7.8,0.9-12.1,2.7c-4.3,1.8-6.5,4.1-6.5,6.9C17.9,51.1,24.1,54.4,32,54.4z M38.5,13.1c-1.8-1.8-4-2.8-6.5-2.8s-4.7,0.9-6.5,2.8
    s-2.8,4-2.8,6.5s0.9,4.7,2.8,6.5s4,2.8,6.5,2.8s4.7-0.9,6.5-2.8c1.8-1.8,2.8-4,2.8-6.5S40.4,14.9,38.5,13.1z"
        />
      );
    case 'tick':
      return (
        <path d="M24.3,41.4L49.6,16l3.4,3.4L24.3,48L11,34.7l3.2-3.4L24.3,41.4z" />
      );
    case 'cancel':
      return (
        <path
          d="M48.7,18.7L35.4,32l13.3,13.3l-3.4,3.4L32,35.4L18.7,48.7l-3.4-3.4L28.6,32L15.3,18.7l3.4-3.4L32,28.6
        l13.3-13.3L48.7,18.7z"
        />
      );
    case 'back':
      return (
        <path d="M51.2,29.6v4.7H22l13.3,13.4L32,51.2L12.8,32L32,12.8l3.4,3.4L22,29.6H51.2z" />
      );
    case 'menu-vertical':
      return (
        <path
          d="M35.4,21c-1,1-2.1,1.5-3.4,1.5c-1.3,0-2.4-0.5-3.4-1.5c-1-1-1.5-2.1-1.5-3.4c0-1.3,0.5-2.4,1.5-3.4
    c1-1,2.1-1.5,3.4-1.5c1.3,0,2.4,0.5,3.4,1.5c1,1,1.5,2.1,1.5,3.4C36.8,18.9,36.3,20.1,35.4,21z M28.6,28.6c1-1,2.1-1.5,3.4-1.5
    c1.3,0,2.4,0.5,3.4,1.5c1,1,1.5,2.1,1.5,3.4c0,1.3-0.5,2.4-1.5,3.4c-1,1-2.1,1.5-3.4,1.5c-1.3,0-2.4-0.5-3.4-1.5
    c-1-1-1.5-2.1-1.5-3.4C27.2,30.7,27.7,29.6,28.6,28.6z M28.6,43c1-1,2.1-1.5,3.4-1.5c1.3,0,2.4,0.5,3.4,1.5c1,1,1.5,2.1,1.5,3.4
    c0,1.3-0.5,2.4-1.5,3.4c-1,1-2.1,1.5-3.4,1.5c-1.3,0-2.4-0.5-3.4-1.5c-1-1-1.5-2.1-1.5-3.4C27.2,45.1,27.7,44,28.6,43z"
        />
      );
    case 'up-arrow':
      return (
        <path d="M43,38.9l-11-11l-11,11l-3.4-3.4L32,21.1l14.3,14.4L43,38.9z" />
      );
    case 'down-arrow':
      return (
        <path d="M20.9,25.1l11,11l11-11l3.4,3.4L31.9,42.9L17.6,28.5L20.9,25.1z" />
      );
    case 'unknown':
      return (
        <path
          d="M15.2,15.1c4.6-4.7,10.2-7,16.8-7c6.5,0,12.1,2.3,16.8,7c4.6,4.7,7,10.3,7,16.9c0,6.6-2.3,12.2-7,16.9
        c-4.6,4.7-10.2,7-16.8,7c-6.5,0-12.1-2.3-16.8-7c-4.6-4.7-7-10.3-7-16.9C8.3,25.4,10.6,19.8,15.2,15.1z M39.2,30.2
        c1.5-1.5,2.2-3.3,2.2-5.4c0-2.6-0.9-4.9-2.8-6.7c-1.9-1.9-4.1-2.8-6.7-2.8c-2.6,0-4.8,0.9-6.7,2.8c-1.9,1.9-2.8,4.1-2.8,6.7h4.7
        c0-1.3,0.5-2.4,1.4-3.4c1-1,2.1-1.5,3.3-1.5c1.3,0,2.4,0.5,3.3,1.5c1,1,1.4,2.1,1.4,3.4c0,1.3-0.5,2.4-1.4,3.4l-2.9,3
        c-1.9,2-2.8,4.3-2.8,6.7v1.2h4.7c0-2.5,0.9-4.7,2.8-6.7L39.2,30.2z M34.3,48.7V44h-4.7v4.7H34.3z"
        />
      );
    case 'reset':
      return (
        <path
          d="M32,13c5.3,0,9.8,1.9,13.6,5.6c3.7,3.7,5.6,8.2,5.6,13.4c0,5.3-1.9,9.8-5.7,13.6
        c-3.8,3.7-8.3,5.6-13.5,5.6s-9.7-1.9-13.5-5.6c-3.8-3.7-5.7-8.3-5.7-13.6h4.8c0,4,1.4,7.3,4.2,10.1S28,46.4,32,46.4
        s7.3-1.4,10.1-4.2s4.2-6.2,4.2-10.1c0-4-1.4-7.3-4.2-10.1s-6.2-4.2-10.1-4.2v9.6l-12-12l12-12C32,3.4,32,13,32,13z"
        />
      );
    case 'warning':
      return (
        <path d="M5.7,49.7L32,4.3l26.3,45.4H5.7z M34.4,33v-9.6h-4.7V33H34.4z M34.4,42.5v-4.8h-4.7v4.8H34.4z" />
      );
    case 'double-left-arrow':
      return (
        <g>
          <polygon points="33,21.3 29.5,18 15.2,32.3 29.6,46.7 33,43.3 22,32.3 	" />
          <polygon points="44.8,21.8 41.4,18.7 27.8,32.3 41.5,46.1 44.8,42.8 34.2,32.3 	" />
        </g>
      );
    case 'double-right-arrow':
      return (
        <g>
          <polygon points="31,20.9 34.5,17.6 48.8,31.9 34.4,46.4 31,42.9 42,31.9 	" />
          <polygon points="19.2,21.4 22.6,18.3 36.2,32 22.5,45.7 19.2,42.5 29.8,32 	" />
        </g>
      );
    case 'refresh':
      return (
        <path
          d="M32,46.3v-7.2l9.5,9.5L32,58.3v-7.2c-5.2,0-9.7-1.9-13.5-5.7s-5.7-8.3-5.7-13.5
          c0-3.7,1-7.1,3-10.2l3.5,3.5c-1.1,2-1.7,4.3-1.7,6.7c0,4,1.4,7.3,4.2,10.1C24.7,44.9,28,46.3,32,46.3z M32,12.8
          c5.2,0,9.7,1.9,13.5,5.7s5.7,8.3,5.7,13.5c0,3.7-1,7.1-3,10.2l-3.5-3.5c1.1-2,1.7-4.3,1.7-6.7c0-4-1.4-7.3-4.2-10.1
          s-6.2-4.2-10.1-4.2v7.2l-9.5-9.5L32,5.7V12.8z"
        />
      );
    case 'save':
      return (
        <>
          <path
            d="M35.2,20.6h5.2c0.3,0,0.6-0.3,0.6-0.6V9.9c0-0.3-0.3-0.6-0.6-0.6h-5.2c-0.3,0-0.6,0.3-0.6,0.6V20
            C34.6,20.3,34.9,20.6,35.2,20.6z"
          />
          <path
            d="M53.4,13.9l-6.1-6.1c-0.2-0.2-0.5-0.3-0.8-0.3h-1.8c-0.6,0-1.1,0.5-1.1,1.1v13.4h-23V8.6c0-0.6-0.5-1.1-1.1-1.1h-7.9
            c-0.6,0-1.1,0.2-1.1,1.1v45.7c0,1.6,0.5,2.1,1.1,2.1h41.1c0.6,0,1.1-0.5,1.1-2.1V14.7C53.7,14.2,53.6,14.1,53.4,13.9z M49.2,50.9
            c0,0.6-0.5,1.1-1.1,1.1H16c-0.6,0-1.1-0.5-1.1-1.1V28.2c0-0.6,0.5-1.1,1.1-1.1H48c0.6,0,1.1,0.5,1.1,1.1V50.9z"
          />
          <rect x="18.5" y="30.6" width="27" height="3.7" />
          <rect x="18.5" y="44.9" width="27" height="3.7" />
          <rect x="18.5" y="37.8" width="27" height="3.6" />
        </>
      );
    case 'delete':
      return (
        <path
          d="M40.4,12.8h8.3v4.8H15.3v-4.8h8.3l2.5-2.4h11.9L40.4,12.8z M17.7,48.7V20h28.7v28.7c0,1.3-0.5,2.4-1.5,3.4
      c-1,1-2.1,1.5-3.4,1.5h-19c-1.3,0-2.4-0.5-3.4-1.5C18.1,51.1,17.7,50,17.7,48.7z M23.5,31.7l5.2,5.2l-5,5l3.4,3.4l5-5l5,5l3.4-3.4
      l-5-5l5-5.2L37,28.3l-5,5.2l-5-5.2L23.5,31.7z"
        />
      );
    case 'article':
      return (
        <path
          d="M52,11.9c0.9,0.9,1.4,2.1,1.4,3.4v33.4c0,1.3-0.5,2.5-1.4,3.4c-0.9,0.9-2.1,1.4-3.4,1.4H15.4
          c-1.3,0-2.5-0.5-3.4-1.4c-0.9-0.9-1.4-2.1-1.4-3.4V15.3c0-1.3,0.5-2.5,1.4-3.4c0.9-0.9,2.1-1.4,3.4-1.4h33.2
          C49.9,10.5,51.1,11,52,11.9z M43.9,20H20.1v4.8h23.8V20z M43.9,29.6H20.1v4.7h23.8V29.6z M36.8,39.2H20.1V44h16.7V39.2z"
        />
      );
    case 'photo':
      return (
        <path
          d="M53.4,48.7c0,1.3-0.5,2.4-1.4,3.4c-1,1-2.1,1.5-3.3,1.5H15.4c-1.3,0-2.4-0.5-3.3-1.5c-1-1-1.4-2.1-1.4-3.4V15.3
          c0-1.3,0.5-2.4,1.4-3.4c1-1,2.1-1.5,3.3-1.5h33.2c1.3,0,2.4,0.5,3.3,1.5c1,1,1.4,2.1,1.4,3.4V48.7z M23.6,35.6l-8.2,10.8h33.2
          L37.9,32l-8.2,10.8L23.6,35.6z"
        />
      );
    case 'video':
      return (
        <path
          d="M53.4,10.5c1.3,0,2.5,0.5,3.4,1.4c0.9,0.9,1.4,2.1,1.4,3.4L58.1,44c0,1.3-0.5,2.4-1.4,3.3
          c-0.9,0.9-2,1.4-3.3,1.4H41.5v4.8H22.5v-4.8H10.6c-1.3,0-2.5-0.4-3.4-1.3c-0.9-0.9-1.4-2-1.4-3.4V15.3c0-1.3,0.5-2.5,1.4-3.4
          c0.9-0.9,2.1-1.4,3.4-1.4H53.4z M53.4,44V15.3H10.6V44H53.4z M41.5,29.6l-16.6,9.5V20L41.5,29.6z"
        />
      );
    case 'comments':
      return (
        <path
          d="M51,8.1c1.3,0,2.4,0.5,3.3,1.4c0.9,0.9,1.4,2,1.4,3.3v28.7c0,1.3-0.5,2.4-1.4,3.4c-0.9,1-2,1.5-3.3,1.5H17.7
        l-9.5,9.5v-43c0-1.3,0.5-2.4,1.4-3.3c0.9-0.9,2-1.4,3.3-1.4H51z M46.3,22.5v-4.8H17.7v4.8H46.3z M17.7,24.8v4.8h28.5v-4.8H17.7z
         M36.8,36.8V32h-19v4.8H36.8z"
        />
      );
    case 'launch':
      return (
        <path
          d="M48.6,48.7V32h4.8v16.7c0,1.3-0.5,2.4-1.4,3.4c-1,1-2.1,1.5-3.3,1.5H15.4c-1.3,0-2.5-0.5-3.4-1.4
        c-0.9-0.9-1.4-2.1-1.4-3.4V15.3c0-1.3,0.5-2.5,1.4-3.4c0.9-0.9,2.1-1.4,3.4-1.4H32v4.8H15.4v33.4H48.6z M36.8,10.5h16.6v16.7h-4.8
        v-8.5L25.3,42.1L22,38.7l23.3-23.4h-8.5V10.5z"
        />
      );
    case 'up-votes':
      return (
        <polygon points="32,13 12.8,32 22.5,32 22.5,51 41.5,51 41.5,32 51.2,32 " />
      );
    case 'ban':
    default:
      return (
        <path
          d="M15.1,15.1c4.7-4.7,10.3-7,16.9-7c6.6,0,12.2,2.3,16.9,7c4.7,4.7,7,10.3,7,16.9c0,6.6-2.3,12.2-7,16.9
    c-4.7,4.7-10.3,7-16.9,7c-6.6,0-12.2-2.3-16.9-7c-4.7-4.7-7-10.3-7-16.9C8.1,25.4,10.5,19.8,15.1,15.1z M32,51.2
    c4.5,0,8.4-1.3,11.8-4L16.9,20.2c-2.7,3.4-4,7.3-4,11.8c0,5.2,1.9,9.7,5.7,13.5C22.3,49.3,26.8,51.2,32,51.2z M47.1,43.8
    c2.7-3.4,4-7.3,4-11.8c0-5.2-1.9-9.7-5.7-13.5c-3.8-3.8-8.3-5.7-13.5-5.7c-4.5,0-8.4,1.3-11.8,4L47.1,43.8z"
        />
      );
  }
};

export interface IProps {
  icon: TIconType;
  title?: string;
  className?: string;
}

const SVGIcon: React.FC<IProps> = ({
  icon,
  title,
  className,
}: IProps): JSX.Element => (
  <svg
    role="img"
    className={`SVGIcon${className ? ` ${className}` : ''}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
  >
    {title && <title>{title}</title>}
    {showIcon(icon)}
  </svg>
);

export default SVGIcon;
